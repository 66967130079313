@import "../sass/variaveis";
@import "../sass/utilities/reset"; 


@import "../sass/utilities/responsivo";
@import "../sass/mixins"; //MIXINS
@import "../sass/variaveis";
@import "../sass/utilities/animation";


//components
@import "../sass/components/body"; //BODY
@import "../sass/components/login"; //LOGIN
//@import "../sass/components/menu/menu";
@import "../sass/components/menu/nav_principal";
@import "../sass/components/footer";


//@import "../sass/components/box_lang";

@include header_section();
@include header_paginations();
@include link_style_1();
@include link_style_2();

// PÁGINAS
main {
    overflow: hidden;
}

.container {
    margin: 0 auto;
    padding: 0 1.5rem;
    width: 100%;
    position: relative;

    @media (min-width: 576px) {
        max-width: 540px;
    }

    @media (min-width: 768px) {
        max-width: 720px;
    }

    @media(min-width: 992px) {
        max-width: 960px;
    }

    @media (min-width: 1216px) {
        max-width: 1216px;
    }
    /*
    @media (min-width: 1400px) {
        max-width: 1400px
    }
    @media (min-width: 1600px) {
        max-width: 1600px
    }*/

}

.body__home{
    @import "../sass/pages/page_home";
}
.body__quem_somos{
    @import "../sass/pages/page_quem_somos";
}
.body__servicos{
    @import "../sass/pages/page_servicos";
}